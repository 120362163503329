(function() {
"use strict"
// =========================================================

window.winnerWhiteEmailDomainsBundleSrc = function() {
	const host = window.location.host
	const bundleSrc = 
        host.startsWith('localhost:8000') ? 'http://' + host + '/script.js' :
        'https://' + (
            !( host.startsWith('alpha') || host.startsWith('localhost') ) ? '' : 'alpha0.'
        ) + 'baza-winner.ru/white-email-domains/script.js'
    return bundleSrc
}

window.winnerWhiteEmailDomainsBundleId = function() {
    return 'winnerWhiteEmailDomains'
}

window.isW7SuitableLogin = function(value, opt) {
    const bundleSrc = winnerWhiteEmailDomainsBundleSrc()
	const bundleId = winnerWhiteEmailDomainsBundleId()
    // console.error({bundleId, bundleSrc, value})
	const asyncClosure = () => 
		new Promise((resolve, reject) => {
			const ret = window._isW7SuitableLogin(value, opt)
			if (ret.error) {
				reject(ret.error)
			} else {
				resolve(ret)
			}
		})
	const alwaysReload = true
	return window.lazyLoadAsyncRun({bundleId, bundleSrc, asyncClosure, alwaysReload})
}

// =========================================================
})()
